import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  makeStyles,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { getCoinBasicDetails, NetworkDetailsList } from "../Utils";
const useStyles = makeStyles((theme) => ({
  curvedDialogue: {
    borderRadius: "12px",
    "& .MuiPaper-root": {
      borderRadius: "10px",
      background: "#0a0a01",
      maxWidth: "450px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#8080806b",
      borderRadius: "50px",
    },
    "& .MuiDialogTitle-root": {
      padding: "10px 24px",
    },
    "& .contentBox": {
      margin: "10px 0",
      display: "flex",
      padding: "10px 0 17px",
      cursor: "pointer",
      borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
      width: "100%",
      border: "0",
      padding: "17px 14px 16px",
      fontSize: "13px",
      minWidth: "0",
      background: "none",
      fontWeight: "300",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #ffffff5e",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:after": {
      left: "0",
      right: "0",
      bottom: "0",
      content: '""',
      position: "absolute",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderBottom: "2px solid #fff",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #f50057",
    },
    "& .newModalBorder": {
      background:
        "linear-gradient(312.78deg, #ffb000 -25.59%, #ff564d 12.16%, #ff0098 39.51%, #5d00c1 55.74%)",
      borderRadius: "10px",
      padding: "2px !important",
    },
    "& .mainbox1": {
      background: "#0a0a01",
      borderRadius: "10px",
      padding: "16px",
      minHeight: "390px",
    },
  },
  mainContentBox: {},
}));
export default function NetworkModule({ open, close, setSelectedChain }) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (search !== "") {
      const filterFun = NetworkDetailsList.filter((data) => {
        return (
          data?.name?.toLowerCase() == search?.toLowerCase() ||
          data?.symbol?.toLowerCase() == search?.toLowerCase()
        );
      });
      setListData(filterFun);
    } else {
      setListData(NetworkDetailsList);
    }
  }, [search]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      fullWidth
      className={classes.curvedDialogue}
    >
      <Box className="newModalBorder">
        <Box className="mainbox1">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  Switch to
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    close();
                    setSearch("");
                  }}
                  style={{ position: "absolute", top: "14px", padding: "0" }}
                >
                  <CloseIcon style={{ color: "#fff" }} />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.mainContentBox}>
              <Box className="searchFiledBox">
                {/* <TextField variant="outlined" placeholder="Search network" /> */}
                <TextField
                  required
                  variant="outlined"
                  placeholder="Search network"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box className="networkListBox">
                {listData &&
                  listData.map((data, i) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={data.chain}
                        className="contentBox"
                        onClick={() => {
                          setSelectedChain(data.chain);
                          close();
                        }}
                      >
                        <img src={data.image} alt="" width="20px" />
                        <Typography
                          variant="body2"
                          style={{
                            color: "#fff",
                            marginLeft: "10px",
                            fontWeight: "300",
                          }}
                        >
                          {data.name}
                        </Typography>
                      </Box>
                    );
                  })}
                {listData && listData.length === 0 && (
                  <Box
                    mt={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ color: "#fff" }}>
                      No network found!
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}
