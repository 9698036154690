import { Box, Typography } from "@material-ui/core";
import React from "react";

export default function CustomTaost({ text, color }) {
  return (
    <Box
      style={{
        height: "41px",
        background: color,
        color: "rgb(0, 0, 0)",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        padding: "0px 15px",
      }}
    >
      <Typography style={{ fontSize: "18px", fontWeight: "400" }}>
        {text}
      </Typography>
    </Box>
  );
}
