import React, { useState, useEffect, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Divider,
  Link,
  Select,
  MenuItem,
  FormHelperText,
  Avatar,
  IconButton,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EthLogo from "../../images/ethman.png";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import Web3 from "web3";
import axios from "axios";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";
import { ethers } from "ethers";
import {
  BSCSwapAgentABIData,
  EthSwapAgentABIData,
  IBEP20ABIData,
  EthereumChainId,
  BinanceChainId,
  getRPCDetails,
  AvalancheChainId,
  MaticChainId,
  getCoinBasicDetails,
  getCurrentSwapAddress,
  apiURL,
  FieroChainId,
  wFiero,
  getCoinSwapAddress,
  NetworkDetailsList,
} from "../../Utils/index";
import {
  getBinanceBalance,
  getEthereumBalance,
  sortAddress,
  getTotalSupply,
  setCryptoDecimals,
} from "../../hooks/operations";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import HelpIcon from "@material-ui/icons/Help";
import ScreenLoader from "../../components/ScreenLoader";
const useStyles = makeStyles((theme) => ({
  transferButton: {
    color: "#fff",
    padding: "10px 25px",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "50px",
    marginLeft: "10px",
    position: "relative",
    zIndex: "9",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "&:hover": {
      color: "#ffffff",
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
      backgroundOrigin: "border-box",
    },
    "& .MuiButton-contained.Mui-disabled": {
      color: "rgb(8 8 8 / 48%) !important",
      background: "rgb(200 200 199 / 40%) !important",
    },
    "@media(max-width:603px)": {
      padding: "4px 0px",
    },
  },
  gradientBorder: {
    borderRadius: "15px",
    padding: "2px!important",
  },
  mainBox: {
    // padding: "100px 0",
    // margin: "0px 40px",
    "& .MuiPopover-paper": {
      background: " #0c0508",
    },
    "& .MuiPaper-root": {
      background: " #0c0508",
    },
  },

  mainbridge: {
    padding: "30px 20px 30px",
    background: " #0c0508",
    borderRadius: "15px",
    // marginTop: "50px",
    "& h1": {
      fontFamily: "Good Times W00 Bold",
      fontSize: "50px",
      textAlign: "center",
      textTransform: "uppercase",
      marginBottom: "30px",
    },
    "& h6": {
      color: "#FFFBFB",
      fontSize: "14px",
      fontWeight: "500",
    },
    "& h5": {
      fontSize: "18px",
      fontWeight: "300",
      marginBottom: "20px",
      "@media(max-width:603px)": {
        fontSize: "14px",
      },
    },
    "& h2": {
      fontSize: "35px",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px !important",
      },
    },
  },
  evenBox: {
    background: "rgba(255, 255, 255, 0.025)",
    borderRadius: "10px",
    padding: "20px",
    position: "relative",
    zIndex: "999",
    "& .displaySpace": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      fontSize: "13px",
      fontWeight: "300",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
      width: "100%",
      border: "0",
      padding: "6px 0 14px",
      minWidth: "0",
      background: "none",
      fontSize: "30px",
      fontWeight: "300",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #ffffff5e",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:after": {
      left: "0",
      right: "0",
      bottom: "0",
      content: '""',
      position: "absolute",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderBottom: "2px solid #fff",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #f50057",
    },
    "& .logoBox": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    "& .selectBox": {
      display: "flex",
      padding: "8px 30px 8px 10px",
      background: "rgb(31, 27, 26)",
      alignItems: "center",
      borderRadius: "27px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "16px",
      },
    },
    "& .coinname": {
      color: "#fff",
      whiteSpace: "pre",
    },
  },
  rotateIcon: {
    cursor: "pointer",
    width: "25px",
    height: "25px",
    display: "flex",
    background: "rgb(25 19 22)",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    padding: "6px",
  },
  arrowIcon: {
    "& svg": {
      rotate: "90deg",
      fontSize: "20px",
      color: "#ffffffe8",
    },
  },
  selectgradientBox1: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
    },
    // zIndex: "9999",
    "& .MuiPopover-paper": {
      background: " #0c0508",
    },
    "& .MuiPaper-root": {
      background: " #0c0508",
    },
    "& .MuiInputBase-input ": {
      padding: "8px 30px 8px 0px",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    },
    "& .MuiSelect-icon": {
      color: "#fff",
    },
    "& .MuiAvatar-img": {
      width: "auto",
      maxWidth: "100%",
      "& .MuiAvatar-root": {
        width: "auto",
        maxWidth: "100%",
      },
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: "8px",
        background: "rgb(18 11 14)",
      },
      "& .MuiMenuItem-root": {
        color: "#fff !important",
      },
    },
  },
  selectInnerBox: {
    "& .logoBox": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      marginRight: "10px",
    },
  },
  logoBox: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  listTextBox: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: "'Sora', sans-serif",
    lineHeight: "30px",
  },
  curvedDialogue: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      background: "#0a0a01",
      maxWidth: "450px",
    },
    "& .newModalBorder": {
      background:
        "linear-gradient(312.78deg, #ffb000 -25.59%, #ff564d 12.16%, #ff0098 39.51%, #5d00c1 55.74%)",
      borderRadius: "10px",
      padding: "2px !important",
    },
    "& .mainbox1": {
      background: "#0a0a01",
      borderRadius: "10px",
      padding: "16px",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Bridge = ({
  chainId,
  account,
  selectedChain,
  setSelectedChain,
  isSwaped,
  setIsSwaped,
  connect,
  setErrMsg,
  callBack,
}) => {
  const classes = useStyles();
  const [openEthereum, setEthereum] = useState(false);
  const [openFierex, setFierex] = useState(false);
  const [tokenBalanceBinance, setTokenBalanceBinance] = useState("");
  const [tokenBalanceEthereum, setTokenBalanceEthereum] = useState("");
  const [totalSupplyEthereum, setTotalSupplyEthereum] = useState(0);
  const [transferAmount, setTransferAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [isTransfering, setIsTransfering] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
  const [swapFee, setSwapFee] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const transferHandlerBNB = async () => {
    if (transferAmount !== "" && transferAmount > 0) {
      try {
        setIsTransfering(true);
        const swapAddress = getCurrentSwapAddress(chainId);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(
          BSCSwapAgentABIData,
          swapAddress
        );
        const mappingAddress = await contract.methods
          .swapMappingCoin2Token(wFiero)
          .call();
        const swapFeeFun = await contract.methods.swapFee().call();
        contract.methods
          .swapToken2Coin(
            mappingAddress,
            web3.utils.toWei(transferAmount.toString())
          )
          .send({ from: account, gas: 3000000, value: swapFeeFun?.toString() })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                "fillFiero2BSCSwapFun",
                FieroChainId
              );
              setOpen(false);
              setTransferAmount("");
              getBalanceFun(account);
              setIsTransfering(false);
              callBack();
              setErrMsg({
                text: "Your transaction has been completed successfully.",
                color: "#4ecb38",
              });
            } else {
              setIsTransfering(false);
            }
          })
          .catch((err) => {
            setIsTransfering(false);
            console.log("ERROR", err);
          });
      } catch (err) {
        console.log("err", err);
        setIsTransfering(false);
      }
    }
  };

  const transferHandler = async () => {
    setErrMsg({
      text: "",
      color: "",
    });
    if (transferAmount !== "" && transferAmount > 0) {
      if (Number(tokenBalanceBinance?.binanceBalance) <= 0) {
        setErrMsg({
          text: "Your wallet coin balance is low.",
          color: "red",
        });

        return;
      }
      if (
        Number(tokenBalanceBinance?.binanceBalance) < Number(transferAmount)
      ) {
        setErrMsg({
          text: "You don't have enough coin in your wallet.",
          color: "red",
        });
        return;
      }
      try {
        setIsTransfering(true);
        const swapAddress = getCoinSwapAddress(selectedChain);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = new web3.eth.Contract(
          EthSwapAgentABIData,
          swapAddress
        );
        const swapFeeFun = await contract.methods.swapFee().call();
        const totalAmount =
          parseInt(web3.utils.toWei(transferAmount)) + parseInt(swapFeeFun);

        contract.methods
          .swapCoin2Token()
          .send({
            from: account,
            gas: 3000000,
            value: totalAmount,
          })
          .then(async function (receipt) {
            if (receipt.status) {
              setOpen(true);
              await fillSwapFun(
                receipt.transactionHash,
                account,
                ethers.utils.parseEther(transferAmount).toString(),
                "fillFiero2BSCSwapFun",
                selectedChain
              );
              setOpen(false);
              setTransferAmount("");
              getBalanceFun(account);
              setIsTransfering(false);
              callBack();
              setErrMsg({
                text: "Your transaction has been completed successfully.",
                color: "#4ecb38",
              });
            } else {
              setIsTransfering(false);
            }
          })
          .catch((err) => {
            setIsTransfering(false);
            console.log("ERROR", err);
          });
      } catch (err) {
        console.log("err", err);
        setIsTransfering(false);
      }
    } else {
      setErrMsg({
        text: "Please enter a valid transfer amount.",
        color: "red",
      });
    }
  };

  const approveToken = async () => {
    setErrMsg({
      text: "",
      color: "",
    });
    if (transferAmount !== "" && transferAmount > 0) {
      if (Number(tokenBalanceEthereum) <= 0) {
        setErrMsg({
          text: "Your wallet token balance is low.",
          color: "red",
        });
        return;
      }
      if (Number(tokenBalanceEthereum) < Number(transferAmount)) {
        setErrMsg({
          text: "You don't have enough token in your wallet.",
          color: "red",
        });
        return;
      }
      try {
        setIsTransfering(true);
        const web3 = (window.web3 = new Web3(window.ethereum));
        const swapAddress = getCurrentSwapAddress(chainId);

        const contractSWap = new web3.eth.Contract(
          BSCSwapAgentABIData,
          swapAddress
        );
        const mappingAddress = await contractSWap.methods
          .swapMappingCoin2Token(wFiero)
          .call();
        const contract = new web3.eth.Contract(IBEP20ABIData, mappingAddress);
        const transactionParameters = {
          to: mappingAddress,
          from: account,
          data: contract.methods
            .approve(swapAddress, web3.utils.toWei(transferAmount.toString()))
            .encodeABI(),
        };
        web3.eth
          .sendTransaction(transactionParameters)
          .on("transactionHash", (hash) => {})
          .once("confirmation", (confirmationNumber, receipt) => {
            if (receipt.status) {
              transferHandlerBNB();
            }
          })
          .on("error", (error) => {
            setIsTransfering(false);
            console.error("error", error);
          });
      } catch (err) {
        console.error("ERROR: ".err);
        setIsTransfering(false);
      }
    } else {
      setErrMsg({
        text: "Please enter a valid transfer amount.",
        color: "red",
      });
    }
  };

  const fillSwapFun = async (
    hash,
    userAddress,
    tokenAmount,
    endPoint,
    chain
  ) => {
    try {
      const res = await axios.post(`${apiURL}/${endPoint}`, {
        hash: hash,
        userAddress: userAddress,
        tokenAmount: tokenAmount,
        chainId: parseInt(chain)?.toString(), //selectedChain
        toChainId: parseInt(chainId)?.toString(), //currentChain
      });
      if (res.data.responseCode) {
        return true;
      }
    } catch (err) {
      console.log("ERROR", err);
      return false;
    }
  };

  useEffect(() => {
    setTokenBalanceBinance();
    setTokenBalanceEthereum(0);
    setTotalSupplyEthereum(0);
    if (account) {
      getBalanceFun(account);
    }
  }, [account, isSwaped, selectedChain]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBalanceFun = async (account) => {
    setTokenBalanceBinance(await getBinanceBalance(account));
    setTokenBalanceEthereum(await getEthereumBalance(account, selectedChain));
    setTotalSupplyEthereum(
      await getTotalSupply(IBEP20ABIData, account, selectedChain)
    );
  };

  useEffect(() => {
    if (window.localStorage.getItem("risk")) {
      setIsAccept(true);
    } else {
      setIsAccept(false);
    }
  }, [window.localStorage.getItem("risk")]);

  useEffect(async () => {
    const swapAddress = getCurrentSwapAddress(selectedChain);
    const url = getRPCDetails(selectedChain);
    const web3 = new Web3(new Web3.providers.HttpProvider(url.url));
    const contractSwap = new web3.eth.Contract(
      BSCSwapAgentABIData,
      swapAddress
    );
    const mappingAddress = await contractSwap.methods
      .swapMappingCoin2Token(wFiero)
      .call();
    setSelectedTokenAddress(mappingAddress);
  }, [selectedChain]);
  const getGasFee = async () => {
    try {
      const web3 = (window.web3 = new Web3(window.ethereum));
      if (!isSwaped) {
        const swapAddress = getCoinSwapAddress(selectedChain);
        const contract = new web3.eth.Contract(
          EthSwapAgentABIData,
          swapAddress
        );
        const swapFeeFun = await contract.methods.swapFee().call();
        setSwapFee(web3.utils.fromWei(swapFeeFun?.toString()));
      } else {
        const swapAddress = getCurrentSwapAddress(chainId);
        const contract = new web3.eth.Contract(
          BSCSwapAgentABIData,
          swapAddress
        );
        const swapFeeFun = await contract.methods.swapFee().call();
        setSwapFee(web3.utils.fromWei(swapFeeFun?.toString()));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGasFee();
  }, [isSwaped]);

  return (
    <>
      <Box className={classes.mainBox}>
        {/* <Container maxWidth="lg"> */}

        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="info">
            {`Your tokens will be bridged to ${
              !isSwaped
                ? getCoinBasicDetails(selectedChain)?.symbol
                : getCoinBasicDetails(selectedChain)?.symbol
            } shortly`}
          </Alert>
        </Snackbar>
        <Box className={classes.gradientBorder}>
          <Box className={classes.mainbridge}>
            <Box className={classes.evenBox}>
              <Box className="displaySpace">
                <Typography variant="body1">
                  {isSwaped ? "To" : "From"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ textAlign: "right" }}>
                    Balance:&nbsp;
                    <span style={{ color: "#fff" }}>
                      {tokenBalanceBinance
                        ? setCryptoDecimals(tokenBalanceBinance?.binanceBalance)
                        : "0"}
                    </span>
                  </Typography>
                  <IconButton onClick={() => setFierex(true)}>
                    <HelpIcon style={{ color: "#fff" }} />
                  </IconButton>
                </Box>
              </Box>

              <Box className="displaySpace">
                <Box style={{ width: "100%" }}>
                  {/* <Typography variant="body1">
                    {isSwaped ? "To" : "From"}
                  </Typography> */}
                  <TextField
                    type="number"
                    required
                    id="standard-required"
                    placeholder="0.0"
                    value={transferAmount}
                    onChange={(e) => setTransferAmount(e.target.value)}
                    disabled={isTransfering || isSwaped}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/"
                      ) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Box className="selectBox" ml={4} mt={1}>
                    {" "}
                    <Avatar
                      src="/images/coins/fiero.png"
                      alt="logo"
                      className="logoBox"
                    ></Avatar>
                    <Typography variant="body1" className="coinname">
                      Fiero Smart Chain
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.circleBox} mt={2} align="center">
              <Box className={classes.arrowIcon}>
                <Box
                  className={classes.rotateIcon}
                  disabled={isTransfering}
                  onClick={() => {
                    setIsSwaped(!isSwaped);
                    setTransferAmount("");
                  }}
                >
                  {isSwaped ? <HiArrowNarrowLeft /> : <HiArrowNarrowRight />}
                </Box>
              </Box>
            </Box>

            <Box className={classes.evenBox} mt={2}>
              <Box className="displaySpace">
                <Typography variant="body1">
                  {isSwaped ? "From" : "To"}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">
                    Balance:&nbsp;
                    <span style={{ color: "#fff" }}>
                      {tokenBalanceEthereum
                        ? setCryptoDecimals(tokenBalanceEthereum)
                        : "0"}
                    </span>
                  </Typography>

                  <IconButton
                    onClick={() => setEthereum(true)}
                    disabled={tokenBalanceEthereum ? false : true}
                  >
                    <HelpIcon style={{ color: "#fff" }} />
                  </IconButton>
                </Box>
              </Box>
              <Box className="displaySpace">
                <TextField
                  type="number"
                  required
                  placeholder="0.0"
                  id="standard-disabled"
                  value={transferAmount}
                  onChange={(e) => setTransferAmount(e.target.value)}
                  disabled={isTransfering || !isSwaped}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />

                <Box className={classes.selectgradientBox1} ml={4} mt={1}>
                  <Select
                    variant="outlined"
                    value={selectedChain}
                    onChange={(e) => {
                      setSelectedChain(e.target.value);
                      window.sessionStorage.setItem("chainId", e.target.value);
                    }}
                    disabled={isTransfering}
                    className={classes.selectgradientBox1}
                    style={{
                      display: "flex",
                      padding: "6px 30px 6px 10px",
                      background: "rgb(31, 27, 26)",
                      alignItems: "center",
                      borderRadius: "50px",
                      minWidth: "230px",
                    }}
                  >
                    {NetworkDetailsList &&
                      NetworkDetailsList.map((data, i) => {
                        return (
                          <MenuItem value={data.chain} key={`chainSelect${i}`}>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.logoBox}>
                                <Avatar src={data.image} alt="logo"></Avatar>
                              </Box>
                              {data.name}
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
              </Box>
            </Box>
            {!isAccept && (
              <Box
                className={classes.evenBox}
                mt={2}
                style={{ padding: "10px 13px 10px 25px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Box className={classes.rotateIcon}>
                      <img src="images/emoji.png" style={{ width: "15px" }} />
                    </Box>{" "}
                    <Typography variant="h6" style={{ marginLeft: "12px" }}>
                      This project is in beta phase right now. Use at your own
                      risk.
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    className={classes.transferButton}
                    disableRipple
                    onClick={() => {
                      setIsAccept(true);
                      window.localStorage.setItem("risk", true);
                    }}
                    style={{ borderRadius: "10px" }}
                  >
                    Agree
                  </Button>
                </Box>
              </Box>
            )}

            <Box
              className={classes.evenBox}
              mt={2}
              style={{ padding: "10px 13px 10px 25px" }}
            >
              <Box className="displaySpace">
                <Box display="flex" alignItems="center">
                  <Box className={classes.rotateIcon}>
                    <img src="images/light.png" style={{ width: "15px" }} />
                  </Box>{" "}
                  <Typography variant="h6" style={{ marginLeft: "12px" }}>
                    Reminder
                  </Typography>
                </Box>
              </Box>
              <Box>
                <ul style={{ marginLeft: "24px", padding: "0px" }}>
                  <li className={classes.listTextBox}>
                    {`Crosschain swap fee is ${setCryptoDecimals(
                      swapFee
                    )} tokens.`}
                  </li>
                  {!isAccept && (
                    <li className={classes.listTextBox}>
                      This project is in beta. Use at your risk.
                    </li>
                  )}

                  <li className={classes.listTextBox}>
                    No min-max crosschain amount.
                  </li>
                </ul>
              </Box>
            </Box>
            <Box mt={2} align="center">
              <Button
                variant="contained"
                className={classes.transferButton}
                disableRipple
                onClick={!isSwaped ? transferHandler : approveToken}
                disabled={isTransfering || !isAccept}
              >
                Swap
                {isTransfering && <ButtonCircularProgress />}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openEthereum}
        onClose={() => setEthereum(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.curvedDialogue}
      >
        <Box className="newModalBorder">
          <Box className="mainbox1">
            <Box display="flex" p={2} pl={2} alignItems="center">
              <img
                src={getCoinBasicDetails(selectedChain)?.image}
                alt="eth mannet"
                width="43px"
                style={{ marginRight: "10px" }}
              />
              <Typography variant="h6" style={{ color: "#fff" }}>
                {getCoinBasicDetails(selectedChain)?.name}
              </Typography>
            </Box>

            <DialogContent className={classes.dialogueContent}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Bridge Address
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {selectedChain &&
                      getCurrentSwapAddress(selectedChain) &&
                      sortAddress(getCurrentSwapAddress(selectedChain))}
                  </Typography>
                  <Link
                    href={`${
                      getRPCDetails(selectedChain)?.block_url
                    }/address/${getCurrentSwapAddress(selectedChain)}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FF564D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                      className={`${classes.links} jss226`}
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Token Address
                </Typography>

                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {selectedTokenAddress && sortAddress(selectedTokenAddress)}
                  </Typography>
                  <Link
                    href={`${
                      getRPCDetails(selectedChain)?.block_url
                    }/address/${selectedTokenAddress}`}
                    className={classes.links}
                    target="_blank"
                  >
                    <svg
                      className={`${classes.links} jss226`}
                      // class="jss226"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FF564D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Total Token Supply
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    marginRight: "10px",
                    fontWeight: "300",
                    fontSize: "14px",
                  }}
                >
                  {setCryptoDecimals(totalSupplyEthereum)}
                </Typography>
              </Box>
              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Your Token Balance
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {setCryptoDecimals(tokenBalanceEthereum)}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openFierex}
        onClose={() => setFierex(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.curvedDialogue}
      >
        <Box className="newModalBorder">
          <Box className="mainbox1">
            <Box display="flex" p={3} pl={2} alignItems="center">
              <img src={`/images/logo11.png`} alt="" width="43px" />

              <Typography
                variant="h6"
                style={{ marginLeft: "10px", color: "#fff" }}
              >
                Fiero Smart Chain
              </Typography>
            </Box>

            <DialogContent className={classes.dialogueContent}>
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
                mb={1}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Bridge Address
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {getCoinSwapAddress(selectedChain) &&
                      sortAddress(getCoinSwapAddress(selectedChain))}
                  </Typography>
                  <Link
                    href={`https://fieroscan.com/address/${getCoinSwapAddress(
                      selectedChain
                    )}`}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FF564D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      width="20px"
                      className={`${classes.links} jss226`}
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </Link>
                </Box>
              </Box>

              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
                mb={1}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Total Fiero Locked
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {tokenBalanceBinance &&
                      setCryptoDecimals(tokenBalanceBinance.lockedBalance)}
                  </Typography>
                </Box>
              </Box>

              <Divider light={true} />

              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.itemBox}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Your Fiero Balance
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      marginRight: "10px",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {tokenBalanceBinance &&
                      setCryptoDecimals(tokenBalanceBinance.binanceBalance)}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
      <ScreenLoader
        open={isTransfering}
        close={() => setIsTransfering(false)}
      />
    </>
  );
};

export default Bridge;
