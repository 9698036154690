import React from "react";
const Logo = (props) => {
  return (
    <img
      src={props.mobileView ? "/images/coins/fiero.png" : "/images/logo.png"}
      alt="Logo"
      {...props}
      style={props.mobileView ? { width: "50px" } : { width: "170px" }}
    />
  );
};

export default Logo;
