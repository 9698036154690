import { ethers } from "ethers";
import Web3 from "web3";
import {
  IBEP20ABIData,
  fiero_RPC,
  getRPCDetails,
  ETHswap,
  getCurrentSwapAddress,
  BSCSwapAgentABIData,
  wFiero,
} from "../Utils/index";

export function checkMetaMask() {
  let isMetaMask = false;
  if (window.ethereum) {
    isMetaMask = true;
  }
  return isMetaMask;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export async function getProvider() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  return provider;
}

export async function getBinanceBalance(account) {
  const web3 = new Web3(new Web3.providers.HttpProvider(fiero_RPC));
  const tx = await web3.eth.getBalance(account);

  const tx1 = await web3.eth.getBalance(ETHswap);
  let obj = {
    binanceBalance: web3.utils.fromWei(tx),
    lockedBalance: web3.utils.fromWei(tx1),
  };

  return obj;
}

export async function getEthereumBalance(account, chainId) {
  const url = getRPCDetails(chainId);
  const swapAddress = getCurrentSwapAddress(chainId);
  const web3 = new Web3(new Web3.providers.HttpProvider(url.url));
  const contractSwap = new web3.eth.Contract(BSCSwapAgentABIData, swapAddress);
  const mappingAddress = await contractSwap.methods
    .swapMappingCoin2Token(wFiero)
    .call();
  const contract = new web3.eth.Contract(IBEP20ABIData, mappingAddress);
  const tx = await contract.methods.balanceOf(account).call();
  return web3.utils.fromWei(tx);
}

export const getTotalSupply = async (abi, account, chainId) => {
  let totolSupply = 0;
  try {
    const swapAddress = getCurrentSwapAddress(chainId);
    const url = getRPCDetails(chainId);
    const web3 = new Web3(new Web3.providers.HttpProvider(url.url));
    const contractSwap = new web3.eth.Contract(
      BSCSwapAgentABIData,
      swapAddress
    );
    const mappingAddress = await contractSwap.methods
      .swapMappingCoin2Token(wFiero)
      .call();
    const contract = new web3.eth.Contract(abi, mappingAddress);
    await contract.methods
      .totalSupply()
      .call({ from: account, gas: 3000000 })
      .then(async function (receipt) {
        totolSupply = await web3.utils.fromWei(receipt);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  } catch (err) {
    console.log("err", err);
  }
  return totolSupply;
};

export async function getSignerData() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  const signer = await provider.getSigner();
  return signer;
}

export function getMyAccount() {
  const myAdd = window.sessionStorage.getItem("account");
  return myAdd;
}

export async function getMyBalance() {
  const myAdd = getMyAccount();
  const provider = await getProvider();
  const providerBalance = await provider.getBalance(myAdd);
  return providerBalance;
}

export async function connectToWallet() {
  window.sessionStorage.removeItem("chainId");
  await window.ethereum.request({ method: "eth_requestAccounts" });
  const provider = await getProvider();
  const signer = await provider.getSigner();
  let myAddress = await signer.getAddress();
  window.sessionStorage.setItem("account", myAddress);
  return myAddress;
}
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase();
  if (decimal?.includes("e+")) {
    const exponentialSplitted = decimal?.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal?.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal?.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};
export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0);

  amt = amt?.replace(",", "");
  let arr = amt?.toString().split(".");

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString()
      ).toString();
    } else {
      return numberWithCommas(amt).toString();
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString();
    }
    return "0";
  }
};
const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 6);

  let arr = str.split(".");

  let numbers = arr[0];
  let decimalNum = "";
  if (arr.length > 1) {
    decimalNum = arr[1];
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalNum}`;
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString();
  if (str?.includes(".")) {
    str = str.slice(0, str.indexOf(".") + (decimals + 1));
  }
  return str;
};
