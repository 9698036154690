import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Drawer,
  Hidden,
  Button,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import RepeatIcon from "@material-ui/icons/Repeat";
import ExploreIcon from "@material-ui/icons/Explore";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import TelegramIcon from "@material-ui/icons/Telegram";
import InstagramIcon from "@material-ui/icons/Instagram";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    // top: "78px",
    // width: "245px",
    // height: "calc(100vh - 430px)",
    // padding: "10px 22px",
    // marginLeft: "10px",
    // background: "#1c191c80",
    // boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    // borderRadius: "10px",
    top: "82px",
    width: "250px",
    height: "calc(100% - 182px)",
    margin: "5px 10px 10px 15px",
    background: "#1c191c80",
    marginTop: "21px",
    marginLeft: "13px",
    borderRadius: "10px",
    padding: "10px",
    "& .footer": {
      left: "4px",
      bottom: "0",
      position: "absolute",
      padding: "20px",
    },
  },

  sideMenuBox: {
    position: "relative",
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  connect: {
    display: "flex",
    justifyContent: "flex-start",
    filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
    color: "rgba(255, 255, 255, 0.6)",
    borderRadius: "14px",
    whiteSpace: "pre",
    fontSize: "15px",
    lineHeight: " 21px",
    padding: "10px 22px",
    fontWeight: "400",
    marginBottom: "5px",
    "&:hover": {
      color: "#ffff",
      background:
        "linear-gradient(93.14deg, #FFB000 -20.75%, #FF564D 11.84%, #FF0098 53.76%, #5D00C1 102.96%)",
      borderRadius: "10px",
    },
  },
  mainFooterBox: {
    width: "100%",
    "& .socialBox": {
      display: "flex",
      alignItems: "center",
      "& button": {
        background: "rgba(255, 255, 255, 0.04)",
        marginRight: "10px",
        padding: "5px",
      },
      "& svg": {
        fontSize: "23px",
        color: "rgba(255, 255, 255, 0.15)",
        "&:hover": {
          color: "#FF2676",
        },
      },
    },
    "& .contentBox": {
      color: "rgba(255, 255, 255, 0.6)",
      "& h4": {
        fontSize: "16px",
        fontWeight: "300",
        margin: "13px 0px",
      },
      "& h5": {
        fontSize: "12px",
        fontWeight: "300",
      },
    },
  },
  // background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
  // linear-gradient(96.27deg, rgba(255, 176, 0, 0.87) 57.78%, rgba(255, 87, 76, 0.87) 67.91%, rgba(255, 0, 152, 0.87) 81.36%, rgba(93, 0, 193, 0.87) 97.91%);

  bgColog: {
    background:
      "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
    textShadow: "3px 3px 3px rgba(0, 0, 0, 0.13)",
    WebkitBackgroundClip: "text",
    fontWeight: "bold",
    WebkitTextFillColor: "transparent",
  },
}));

export default function NavBar({
  onMobileClose,
  openMobile,
  setTabView,
  tabView,
}) {
  const classes = useStyles();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2} className={classes.sideMenuBox}>
          <Box>
            <Button
              startIcon={<RepeatIcon style={{ fontSize: "23px" }} />}
              fullWidth
              className={classes.connect}
              style={
                tabView === "Bridge"
                  ? {
                      color: "#ffff",
                      background:
                        "linear-gradient(93.14deg, #FFB000 -20.75%, #FF564D 11.84%, #FF0098 53.76%, #5D00C1 102.96%)",
                      borderRadius: "10px",
                    }
                  : {}
              }
              onClick={() => {
                setTabView("Bridge");
                onMobileClose();
              }}
            >
              Bridge
            </Button>
            <Button
              startIcon={<ExploreIcon style={{ fontSize: "23px" }} />}
              fullWidth
              className={classes.connect}
              onClick={() => window.open("https://fieroscan.com")}
            >
              Explore
            </Button>
            <Button
              startIcon={<ContactMailIcon />}
              fullWidth
              className={classes.connect}
              style={
                tabView === "Support"
                  ? {
                      color: "#ffff",
                      background:
                        "linear-gradient(93.14deg, #FFB000 -20.75%, #FF564D 11.84%, #FF0098 53.76%, #5D00C1 102.96%)",
                      borderRadius: "10px",
                    }
                  : {}
              }
              onClick={() => {
                setTabView("Support");
                onMobileClose();
              }}
            >
              Support
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const footer = (
    <Box className="footer">
      <Box className={classes.mainFooterBox}>
        <Box className="socialBox">
          <IconButton
            size="small"
            onClick={() =>
              window.open(
                "https://instagram.com/fieresofficial?igshid=YmMyMTA2M2Y="
              )
            }
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => window.open("https://t.me/+p56VmMDmvRs4NjNk")}
          >
            <TelegramIcon />
          </IconButton>
        </Box>
        <Box className="contentBox">
          <Typography variant="h4">
            Powered by <span className={classes.bgColog}>Fieres</span>
          </Typography>
          <Typography variant="h5">{`Copyright © ${moment().format(
            "YYYY"
          )} All rights reserved.`}</Typography>
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ background: "#000" }}
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
          {footer}
        </Drawer>
      </Hidden>
    </>
  );
}
