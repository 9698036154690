import React from "react";
// import Bridge from "./Bridge";
import Even from "./Even";
import { Grid } from "@material-ui/core";

const Home = ({
  chainId,
  account,
  selectedChain,
  setSelectedChain,
  setIsSwaped,
  isSwaped,
  connect,
  errMsg,
  setErrMsg,
  callBack,
}) => {
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Even
            callBack={callBack}
            errMsg={errMsg}
            chainId={chainId}
            account={account}
            connect={connect}
            isSwaped={isSwaped}
            selectedChain={selectedChain}
            setErrMsg={(item) => setErrMsg(item)}
            setIsSwaped={(item) => setIsSwaped(item)}
            setSelectedChain={(item) => setSelectedChain(item)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
